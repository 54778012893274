.slick-track {
  height: 100%;
}

.slick-list {
  height: 350px !important;
}

.slick-slide {
  opacity: 1;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}

.slick-slide > div {
  height: 70%;
}

.slick-slide:not(.slick-current) {
  opacity: 0.4;
}

@media (min-width: 960px) {
  .slick-slide:not(.slick-current) {
    opacity: 0.65;
  }
  .slick-prev {
    left: 18vw;
    z-index: 1;
  }
  .slick-next {
    right: 18vw;
  }
  .slick-next, .slick-prev {
    opacity: 0;
    transform: translate(0, -1.5rem);
    transition: opacity 0.3s ease-out;
  }
  .slick-slider:hover .slick-next, .slick-slider:hover  .slick-prev {
    opacity: 1;
  }
  .slick-next::before, .slick-prev::before {
    font-size: 3rem;
  }

}

.time {
  align-self: flex-start;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
}

.divider-container {
  display: flex;
  align-items: center;
}

.divider-container .divider {
  border-bottom: 7px solid #ff41a1;
  opacity: 1;
}

.divider-container .divider-left {
  margin-right: 26px;
  width: 33px;
}
