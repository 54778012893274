@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;700&family=Montserrat:wght@900&display=swap');

body {
  margin: 0;
  font-family: 'JetBrains Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #2b272d;
  min-height: 100vh;

}

p {
  margin: 0;
}
