@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;700&family=Montserrat:wght@900&display=swap);
body {
  margin: 0;
  font-family: 'JetBrains Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #2b272d;
  min-height: 100vh;

}

p {
  margin: 0;
}

:root {
  --room-green: #7eff71;
  --room-red: #ff2424;
  --room-yellow: #ffff52;
  --room-orange: #ff9e29;
}

.talk {
  position: relative;
  background-color: white;
  height: 100%;
  color: #27292e;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  padding: 20px;
  border-radius: 3px;
  margin: 20px 10px;
}

.talk:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 10px;
  bottom: 0;
  left: 0;
  border-radius: 0 0 3px 3px;
}

.talk.red:after {
  background-color: #ff2424;
  background-color: var(--room-red);
}

.talk.orange:after {
  background-color: #ff9e29;
  background-color: var(--room-orange);
}

.talk.green:after {
  background-color: #7eff71;
  background-color: var(--room-green);
}


.talk.yellow:after {
  background-color: #ffff52;
  background-color: var(--room-yellow);
}

.talk.north:after {
  background-color: #C9DAF8;
}

.talk.capacitation:after {
  background-color: #D9D2E9;
}

.talk .header {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.talk .header .title {
  font-size: 1.50em;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.talk .header .author {
  margin-top: 10px;
}

.talk .footer {
  width: 100%;
}

.talk .footer .container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.talk .footer .room{
  font-size: .8em;
  line-height: 1.4em;
}

.talk .footer .room::before {
  content: '*';
  font-size: 1.8em;
  margin-right: 5px;
  font-weight: 700;
  vertical-align: sub;
}

.talk.red .footer .room::before {
  color: #ff2424;
  color: var(--room-red);

}

.talk.orange .footer .room::before {
  color: #ff9e29;
  color: var(--room-orange);
}

.talk.green .footer .room::before {
  color: #7eff71;
  color: var(--room-green);
}

.talk.yellow .footer .room::before {
  color: #ffff52;
  color: var(--room-yellow);
}


.talk .colored-button {
  font-size: .8em;
  color: #27292e;
  border-radius: 4px;
  padding: 8px;
  background: #C9DAF8;
  font-weight: 800;
  text-decoration: none;
}

.talk.red .colored-button {
  background: #ff2424;
  background: var(--room-red);
  color: white;
}

.talk.orange .colored-button {
  background: #ff9e29;
  background: var(--room-orange);
}

.talk.green .colored-button {
  background: #7eff71;
  background: var(--room-green);
}

.talk.yellow .colored-button {
  background: #ffff52;
  background: var(--room-yellow);
}


.button.search {
  text-align: left;
  background: lightgray;
  padding: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: .8em;
  border-radius: 4px;
  margin-top: 0.5em;
  font-weight: 700;
  font-family: 'JetBrains Mono', monospace;
  -webkit-font-smoothing: antialiased;
  border: none;
}

.link-button{
  text-align: left;
  background: none;
  border: none;
  padding: 8px 8px 8px 0;
  color: #2b272d;
  font-size: .8em;
  font-weight: 700;
  font-family: 'JetBrains Mono', monospace;
  -webkit-font-smoothing: antialiased;
}

.modal {
  padding: 50px;
  font-family: sans-serif;
  line-height: 1.2em;
}

.modal .title {
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 0.5em;
}


.close-button:focus {
  outline: none;
}

.slick-track {
  height: 100%;
}

.slick-list {
  height: 350px !important;
}

.slick-slide {
  opacity: 1;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}

.slick-slide > div {
  height: 70%;
}

.slick-slide:not(.slick-current) {
  opacity: 0.4;
}

@media (min-width: 960px) {
  .slick-slide:not(.slick-current) {
    opacity: 0.65;
  }
  .slick-prev {
    left: 18vw;
    z-index: 1;
  }
  .slick-next {
    right: 18vw;
  }
  .slick-next, .slick-prev {
    opacity: 0;
    -webkit-transform: translate(0, -1.5rem);
            transform: translate(0, -1.5rem);
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
  }
  .slick-slider:hover .slick-next, .slick-slider:hover  .slick-prev {
    opacity: 1;
  }
  .slick-next::before, .slick-prev::before {
    font-size: 3rem;
  }

}

.time {
  align-self: flex-start;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
}

.divider-container {
  display: flex;
  align-items: center;
}

.divider-container .divider {
  border-bottom: 7px solid #ff41a1;
  opacity: 1;
}

.divider-container .divider-left {
  margin-right: 26px;
  width: 33px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 30px;
  position: relative;
}

.app-title h1, .app-title h4 {
  margin: 0;
}

.app-title .logo {
  width: 50%;
  max-width: 500px;
}

.app-title .slogan{
  width: 15%;
  max-width: 125px;
  position: absolute;
  right: 0;
  top: 0;
}

.app-title .slogan-small{
  display: none;
}

.app-subtitle {
  text-align: center;
}

.break-container {
  display: flex;
  justify-content: center;
}

.break {
  margin: 10px 0;
  padding: 10px 0;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  color: #ffffff;
  font-size: 2em;
}

.break::before{
  content: '///  ';
  color: #7eff71;
}

.break a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
  .app-title {
    flex-direction: column;
  }

  .app-title .logo {
    width: 100%;
    max-width: 400px;
  }

  .app-title .slogan{
    display: none;
  }

  .app-title .slogan-small{
    display: block;
    width: 25%;
    max-width: 250px;
    position: relative;
    top: -5vh;
    left: -25%;
  }

}

