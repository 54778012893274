.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 30px;
  position: relative;
}

.app-title h1, .app-title h4 {
  margin: 0;
}

.app-title .logo {
  width: 50%;
  max-width: 500px;
}

.app-title .slogan{
  width: 15%;
  max-width: 125px;
  position: absolute;
  right: 0;
  top: 0;
}

.app-title .slogan-small{
  display: none;
}

.app-subtitle {
  text-align: center;
}

.break-container {
  display: flex;
  justify-content: center;
}

.break {
  margin: 10px 0;
  padding: 10px 0;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  color: #ffffff;
  font-size: 2em;
}

.break::before{
  content: '///  ';
  color: #7eff71;
}

.break a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
  .app-title {
    flex-direction: column;
  }

  .app-title .logo {
    width: 100%;
    max-width: 400px;
  }

  .app-title .slogan{
    display: none;
  }

  .app-title .slogan-small{
    display: block;
    width: 25%;
    max-width: 250px;
    position: relative;
    top: -5vh;
    left: -25%;
  }

}
