:root {
  --room-green: #7eff71;
  --room-red: #ff2424;
  --room-yellow: #ffff52;
  --room-orange: #ff9e29;
}

.talk {
  position: relative;
  background-color: white;
  height: 100%;
  color: #27292e;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  padding: 20px;
  border-radius: 3px;
  margin: 20px 10px;
}

.talk:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 10px;
  bottom: 0;
  left: 0;
  border-radius: 0 0 3px 3px;
}

.talk.red:after {
  background-color: var(--room-red);
}

.talk.orange:after {
  background-color: var(--room-orange);
}

.talk.green:after {
  background-color: var(--room-green);
}


.talk.yellow:after {
  background-color: var(--room-yellow);
}

.talk.north:after {
  background-color: #C9DAF8;
}

.talk.capacitation:after {
  background-color: #D9D2E9;
}

.talk .header {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.talk .header .title {
  font-size: 1.50em;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.talk .header .author {
  margin-top: 10px;
}

.talk .footer {
  width: 100%;
}

.talk .footer .container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.talk .footer .room{
  font-size: .8em;
  line-height: 1.4em;
}

.talk .footer .room::before {
  content: '*';
  font-size: 1.8em;
  margin-right: 5px;
  font-weight: 700;
  vertical-align: sub;
}

.talk.red .footer .room::before {
  color: var(--room-red);

}

.talk.orange .footer .room::before {
  color: var(--room-orange);
}

.talk.green .footer .room::before {
  color: var(--room-green);
}

.talk.yellow .footer .room::before {
  color: var(--room-yellow);
}


.talk .colored-button {
  font-size: .8em;
  color: #27292e;
  border-radius: 4px;
  padding: 8px;
  background: #C9DAF8;
  font-weight: 800;
  text-decoration: none;
}

.talk.red .colored-button {
  background: var(--room-red);
  color: white;
}

.talk.orange .colored-button {
  background: var(--room-orange);
}

.talk.green .colored-button {
  background: var(--room-green);
}

.talk.yellow .colored-button {
  background: var(--room-yellow);
}


.button.search {
  text-align: left;
  background: lightgray;
  padding: 4px;
  width: fit-content;
  font-size: .8em;
  border-radius: 4px;
  margin-top: 0.5em;
  font-weight: 700;
  font-family: 'JetBrains Mono', monospace;
  -webkit-font-smoothing: antialiased;
  border: none;
}

.link-button{
  text-align: left;
  background: none;
  border: none;
  padding: 8px 8px 8px 0;
  color: #2b272d;
  font-size: .8em;
  font-weight: 700;
  font-family: 'JetBrains Mono', monospace;
  -webkit-font-smoothing: antialiased;
}

.modal {
  padding: 50px;
  font-family: sans-serif;
  line-height: 1.2em;
}

.modal .title {
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 0.5em;
}


.close-button:focus {
  outline: none;
}
